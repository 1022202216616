import clsx from "clsx";
import { useState } from "react";

import classes from "./PresaleSubmission.module.css";

import Steps from "../../components/PresaleSubmission/Steps/Steps";

import ApproveTokenWarningContainer from "../../components/PresaleSubmission/ApproveToken/WarningContainer/WarningContainer";
import ApproveToken from "../../components/PresaleSubmission/ApproveToken/ApproveToken";
import SubmitPresale from "../../components/PresaleSubmission/SubmitPresale/SubmitPresale";
import PresaleInfo from "../../components/PresaleSubmission/PresaleInfo/PresaleInfo";
import PresaleInfoWarningContainer from "../../components/PresaleSubmission/PresaleInfo/WarningContainer/WarningContainer";
import { useRef } from "react";
const PresaleSubmission = () => {
  const [step, setStep] = useState(1);


  const [address, setAddress] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [softCap, setSoftCap] = useState(0);
  const [hardCap, setHardCap] = useState(0);

  const [minPurchase, setMinPurchase]= useState(0);
  const [maxPurchase, setMaxPurchase]= useState(0);

  const [tokenRate, setTokenRate]= useState(0);

  const [maxSelfWhitelist, setMaxSelfWhitelist]= useState(0);

  const [liquidityPercentage, setLiquidityPercentage]= useState(0)


  const [isTokenDropdownActive, setIsTokenDropdownActive] = useState(false);
  const [selectedToken, setSelectedToken] = useState("USDT");
  const tokenDropdownItems = ["USDT"];

  //presaleAmount
  const [presaleAmount, setPresaleAmount] = useState("");

  //dexListingRate
  const [dexListingRate, setDexListingRate] = useState("");

  //dexLiquidity
  const [dexLiquidity, setDexLiquidity] = useState("");

  //liquidity lockup days
  const [liquidityLockupDays, setLiquidityLockupDays] = useState("");

  //startDateDropDown
  const startDateDropdownRef = useRef(null);
  const [isStartDropdownActive, setIsStartDateDropdownActive] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(
    "06/02/2023  10:17am"
  );
  const startDateDropdownItems = [
    "06/02/2023  10:17am",
    "07/02/2023  10:18am",
    "08/02/2023  10:19am",
  ];

  //endDateDropDown
  const endDateDropdownRef = useRef(null);
  const [isEndDropdownActive, setIsEndDateDropdownActive] = useState(false);
  const [selectedEndDate, setSelectedEndDate] = useState("06/02/2023  10:17am");
  const endDateDropdownItems = [
    "06/02/2023  10:17am",
    "07/02/2023  10:18am",
    "08/02/2023  10:19am",
  ];

  //dexListingDate
  const [dexListingDate, setDexListingDate] = useState("");

  //presaleType
  const presaleTypeDropdownRef = useRef(null);
  const [isPresaleTypeDropdownActive, setIsPresaleTypeDropdownActive] =
    useState(false);
  const [selectedPresaleType, setSelectedPresaleType] = useState("Public");

  return (
    <div className={clsx(classes.mainWrapper, "paddingTop", "container")}>
      <div className={clsx(classes.wrapper)}>
        <Steps step={step} />
        {step === 1 && <ApproveToken 
        setAddress={setAddress}
        address={address}
        setStep={setStep} />}
        {step === 2 && <PresaleInfo setStep={setStep} 
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}

        softCap={softCap}
        setSoftCap={setSoftCap}

        minPurchase={minPurchase}
        setMinPurchase={setMinPurchase}
        maxPurchase={maxPurchase}
        setMaxPurchase={setMaxPurchase}
        tokenRate={tokenRate}
        setTokenRate={setTokenRate}
        maxSelfWhitelist={maxSelfWhitelist}
        setMaxSelfWhitelist={setMaxSelfWhitelist}
        liquidityPercentage={liquidityPercentage}
        setLiquidityPercentage={setLiquidityPercentage}

        hardCap={hardCap}
        setHardCap={setHardCap}
        isTokenDropdownActive={isTokenDropdownActive}  
        setIsTokenDropdownActive={setIsTokenDropdownActive}
        selectedToken={selectedToken}
        setSelectedToken={setSelectedToken}
        tokenDropdownItems={tokenDropdownItems}
        presaleAmount={presaleAmount}
        setPresaleAmount={setPresaleAmount}
        dexListingRate={dexListingRate}
        setDexListingRate={setDexListingRate}
        dexLiquidity={dexLiquidity}
        setDexLiquidity={setDexLiquidity}
        liquidityLockupDays={liquidityLockupDays}
        setLiquidityLockupDays={setLiquidityLockupDays}
        startDateDropdownRef={startDateDropdownRef}
        isStartDropdownActive={isStartDropdownActive}
        setIsStartDateDropdownActive={setIsStartDateDropdownActive}
        selectedStartDate={selectedStartDate}
        setSelectedStartDate={setSelectedStartDate}
        startDateDropdownItems={startDateDropdownItems}
        endDateDropdownRef={endDateDropdownRef}
        isEndDropdownActive={isEndDropdownActive}
        setIsEndDateDropdownActive={setIsEndDateDropdownActive}
        selectedEndDate={selectedEndDate}
        setSelectedEndDate={setSelectedEndDate}
        endDateDropdownItems={endDateDropdownItems}
        dexListingDate={dexListingDate}
        setDexListingDate={setDexListingDate}
        presaleTypeDropdownRef={presaleTypeDropdownRef}
        isPresaleTypeDropdownActive={isPresaleTypeDropdownActive}
        setIsPresaleTypeDropdownActive={setIsPresaleTypeDropdownActive}
        selectedPresaleType={selectedPresaleType}
        setSelectedPresaleType={setSelectedPresaleType}

        

        />}
        {step === 3 && <SubmitPresale 
        setStep={setStep} 
        address={address}
        startDate={startDate}
        endDate={endDate}
        softCap={softCap}
        hardCap={hardCap}
        minPurchase={minPurchase}
        maxPurchase={maxPurchase}
        tokenRate={tokenRate}
        maxSelfWhitelist={maxSelfWhitelist}
        liquidityPercentage={liquidityPercentage}
        
        />}
      </div>
      {step === 1 && <ApproveTokenWarningContainer />}{" "}
      {step === 2 && <PresaleInfoWarningContainer />}
    </div>
  );
};

export default PresaleSubmission;
//
