import React, { useEffect, useState } from "react";
import classes from "./ExploreLaunchPad.module.css";
import SectionHeading from "../../common/SectionHeading/SectionHeading";
import Highlight from "../../common/Highlight/Highlight";
import { banner, magnifier, oracle } from "../../../assets";
import SingleProject from "./SingleProject/SingleProject";
import Button from "../../common/Button/Button";


const ExploreLaunchPad = () => {
  const [projects, setProjects] = useState([]);

  async function fetchIDO(){
    let api = process.env.REACT_APP_API;
    let route = "/launchpads"

    let response = await fetch(api + route);
    let data = await response.json();

    setProjects(data);
  }
  

  useEffect(() => {
  fetchIDO();
  }, []);

  const [searchValue, setSearchValue] = useState("");
  const [filteredItems, setFilteredItems] = useState(projects);
  // useEffect(() => {
  //   setFilteredItems(
  //     projects.filter((el) =>
  //       el.title.toLowerCase().includes(searchValue.toLowerCase())
  //     )
  //   );
  // }, [searchValue]);
  return (
    <section className={classes.wrapper}>
      <SectionHeading>
        Explore <Highlight>Launchpad</Highlight>
      </SectionHeading>
      <div className={classes.searchContainer}>
        <input
          type="search"
          placeholder="Search"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />{" "}
        <img src={magnifier} alt="magnifier" className={classes.icon} />
      </div>
      <div className={classes.projects}>
        {projects.map((el, i) => (
          <SingleProject {...el} key={i} project={el}/>
        ))}
      </div>
      <Button btnPrimary size="md" onClick={() => {}}>
        Explore All
      </Button>
    </section>
  );
};

export default ExploreLaunchPad;
