import React from "react";
import styles from "./Presale.module.css";
import { useParams } from "react-router-dom";
import Text from "../../../common/Text/Text";
const Presale = ({ido_data, token_name, symbol, supply, SaleTokenSymbol}) => {
  const { id } = useParams();
  
  const info = [
    { key: "Sale Type", value: "Public" },
    { key: "Token Name", value: token_name },
    { key: "Token Symbol", value: symbol },
    { key: "Total Supply", value: supply + " " +symbol  },
    // { key: "Token for Presale", value: ido_data?.tokens_for_sale },
    // { key: "Token for Liquidity", value: ido_data?.tokens_for_liquidity },
    { key: "Soft Cap", value: ido_data?.soft_cap + " "+ SaleTokenSymbol },
    { key: "Presale Start Time (UTC)", value: ido_data?.sale_start_time },
    { key: "Presale End Time (UTC)", value: ido_data?.sale_end_time },
    { key: "Liquidity Percent", value: ido_data?.liquidity_percent + "%" },
    // { key: "Liquidity Unlock Time (UTC)", value: "2024-06-02 06:00" },
  ];
  return (
    <div className={styles.presaleContainer}>
      <div className={[styles.listItemContainer, styles.header].join(" ")}>
        <Text sm base100>
          Presale Address
        </Text>

        <div style={{ marginLeft: "auto" }}>
          <Text md base0>
            {ido_data?.ido_contract?.slice(0, 5)}...{" "}
            {ido_data?.ido_contract?.slice(-5)}
          </Text>
          <Text sm base100 opacity=".6" mlAuto textRight>
            Do not send BNB directly to the presale address!
          </Text>
        </div>
      </div>
      <div className={styles.listItems}>
        {info.map((el, i) => (
          <div className={styles.listItemContainer} key={i}>
            <Text sm base100>
              {el.key}
            </Text>
            <Text md base50 textRight mlAuto>
              {el.value}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Presale;
