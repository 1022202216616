import React, { useRef, useState } from "react";
import classes from "./PresaleInfo.module.css";
import clsx from "clsx";
import Text from "../../common/Text/Text";
import Dropdown from "../../common/Dropdown/Dropdown";
import Input from "../../common/Input/Input";
import SelectDex from "./SelectDex/SelectDex";
import OptionalFeatures from "./OptionalFeatures/OptionalFeatures";
import Button from "../../common/Button/Button";
import DatePicker from "./DatePicker/DatePicker";

const PresaleInfo = ({
  setStep,
  isTokenDropdownActive,
  setIsTokenDropdownActive,
  selectedToken,
  setSelectedToken,
  tokenDropdownItems,
  presaleAmount,
  setPresaleAmount,
  softCap,
  setSoftCap,
  hardCap,
  setHardCap,
  dexListingRate,
  setDexListingRate,
  dexLiquidity,
  setDexLiquidity,
  liquidityLockupDays,
  setLiquidityLockupDays,
  startDateDropdownRef,
  isStartDropdownActive,
  setIsStartDateDropdownActive,
  selectedStartDate,
  setSelectedStartDate,
  startDateDropdownItems,
  endDateDropdownRef,
  isEndDropdownActive,
  setIsEndDateDropdownActive,
  selectedEndDate,
  setSelectedEndDate,
  endDateDropdownItems,
  dexListingDate,
  setDexListingDate,
  presaleTypeDropdownRef,
  isPresaleTypeDropdownActive,
  setIsPresaleTypeDropdownActive,
  selectedPresaleType,
  setSelectedPresaleType,

  startDate,
  setStartDate,
  endDate,
  setEndDate,



  minPurchase,
  setMinPurchase,
  maxPurchase,
  setMaxPurchase,
  tokenRate,
  setTokenRate,
  maxSelfWhitelist,
  setMaxSelfWhitelist,
  liquidityPercentage,
  setLiquidityPercentage,
}) => {
  //token dropdonw
  const tokenDropdownRef = useRef(null);
  const presaleTypeDropdownItems = ["Public", "Private", "Protected"];
  
  return (
    <div className={classes.mainWrapper}>
      <div className={clsx(classes.wrapper)}>
        <Text sm base100>
          Fund Raising Token
        </Text>
        <div ref={tokenDropdownRef}>
          <Dropdown
            isActive={isTokenDropdownActive}
            setActive={setIsTokenDropdownActive}
            selectedValue={selectedToken}
            onSelect={(val) => {
              setSelectedToken(val);
              setIsTokenDropdownActive(false);
            }}
            dropdownItems={tokenDropdownItems}
            dropdownRef={tokenDropdownRef}
          />
        </div>
      </div>




      <Text sm base100>
          Start Date (UTC)
        </Text>

        <DatePicker
          value={startDate}
          onChange={(date) => {
            setStartDate(date);
          }}
          placeholder="Start Date"
        />

<Text sm base100>
          End Date (UTC)
        </Text>

      <DatePicker
          value={endDate}
          onChange={(date) => {
            setEndDate(date);
          }}
          placeholder="Start Date"
        />



      <div className={classes.wrapper}>
        <Input
          rootClassName={classes.input}
          label="Soft Cap"
          type="number"
          placeholder="0.0"
          value={softCap}
          onChange={(e) => {
            setSoftCap(e.target.value);
          }}
        />
        <Text xs success>
          Enter the softcap
        </Text>
      </div>

      <div className={classes.wrapper}>
        <Input
          rootClassName={classes.input}
          label="Hard Cap"
          type="number"
          placeholder="0.0"
          value={hardCap}
          onChange={(e) => {
            setHardCap(e.target.value);
          }}
        />
        <Text xs success>
          Enter the hardcap
        </Text>
      </div>








      <div className={classes.wrapper}>
        <Input
          rootClassName={classes.input}
          label="Minimum Purchase"
          type="number"
          placeholder="0.0"
          value={minPurchase}
          onChange={(e) => {
            setMinPurchase(e.target.value);
          }}
        />
        <Text xs success>
          Enter the minimum Purchase
        </Text>
      </div>

      <div className={classes.wrapper}>
        <Input
          rootClassName={classes.input}
          label="Maximum Purchase"
          type="number"
          placeholder="0.0"
          value={maxPurchase}
          onChange={(e) => {
            setMaxPurchase(e.target.value);
          }}
        />
        <Text xs success>
          Enter the maximum purchase
        </Text>
      </div>




      <div className={classes.wrapper}>
        <Input
          rootClassName={classes.input}
          label="Token Rate"
          type="number"
          placeholder="0.0"
          value={tokenRate}
          onChange={(e) => {
            setTokenRate(e.target.value);
          }}
        />
        <Text xs success>
          Enter the minimum Purchase
        </Text>
      </div>

      <div className={classes.wrapper}>
        <Input
          rootClassName={classes.input}
          label="Max Self Whitelist"
          type="number"
          placeholder="0.0"
          value={maxSelfWhitelist}
          onChange={(e) => {
            setMaxSelfWhitelist(e.target.value);
          }}
        />
        <Text xs success>
          Enter the maximum purchase
        </Text>
      </div>

      <div className={classes.wrapper}>
        <Input
          rootClassName={classes.input}
          label="Liquidity Percentage"
          type="number"
          placeholder="0.0"
          value={liquidityPercentage}
          onChange={(e) => {
            setLiquidityPercentage(e.target.value);
          }}
        />
        <Text xs success>
          Enter the maximum purchase
        </Text>
      </div>


      {/* <div className={classes.wrapper}>
        <Input
          rootClassName={classes.input}
          label="Presale Amount"
          type="number"
          placeholder="0.0"
          value={presaleAmount}
          onChange={(e) => {
            setPresaleAmount(e.target.value);
          }}
        />
        <Text xs success>
          Total amount of token for fair launch
        </Text>
      </div>
      <div className={classes.wrapper}>
        <Input
          rootClassName={classes.input}
          label="Dex Listing Rate / Presale Rate (%)"
          warningText="Dex Rate should be equal or less than 100%"
          type="number"
          placeholder="0.0"
          value={dexListingRate}
          onChange={(e) => {
            setDexListingRate(e.target.value);
          }}
        />
        <div>
          <Text xs success className={classes.dexListingRateInfo}>
            Ex; 100 = sale rate and dex listing is the same price,
          </Text>{" "}
          <Text xs success className={classes.dexListingRateInfo}>
            50 = sale rate is 2x better than dex listing price
          </Text>
        </div>
      </div>{" "}
      <div className={classes.wrapper}>
        <Input
          rootClassName={classes.input}
          label="Dex Liquidity (%)"
          type="number"
          placeholder="0.0"
          value={dexLiquidity}
          onChange={(e) => {
            setDexLiquidity(e.target.value);
          }}
        />
      </div>{" "}
      <div className={classes.wrapper}>
        <Input
          rootClassName={classes.input}
          label="Liquidity Lockup (days)"
          type="number"
          placeholder="0.0"
          value={liquidityLockupDays}
          onChange={(e) => {
            setLiquidityLockupDays(e.target.value);
          }}
        />
      </div>{" "}
      <SelectDex />
      <div className={clsx(classes.wrapper)}>
        <Text sm base100>
          Start Date (UTC)
        </Text>

        <DatePicker
          value={startDate}
          onChange={(date) => {
            setStartDate(date);
          }}
          placeholder="Start Date"
        />
      </div>{" "}
      <div className={clsx(classes.wrapper)}>
        <Text sm base100>
          EndDate (UTC)
        </Text>
        <DatePicker
          value={endDate}
          onChange={(date) => {
            setEndDate(date);
          }}
          placeholder="Start Date"
        />
      </div>
      <div className={classes.wrapper}>

        <Input
          rootClassName={classes.input}
          label="Estimated Dex Listing Date (UTC)"
          info="Dex Date should be equal or less than 100%"
          type="number"
          placeholder="0.0"
          value={dexListingDate}
          onChange={(e) => {
            setDexListingDate(e.target.value);
          }}
        />
        <div>
          <Text xs success>
            Total amount of token for fair launch
          </Text>
        </div>
      </div>
      <Text primaryDefault sm className={classes.readMore}>
        Read more about presale type here Docs
      </Text>
      <div className={clsx(classes.wrapper)}>
        <Text sm base100>
          Presale Type
        </Text>
        <div ref={presaleTypeDropdownRef}>
          <Dropdown
            isActive={isPresaleTypeDropdownActive}
            setActive={setIsPresaleTypeDropdownActive}
            selectedValue={selectedPresaleType}
            onSelect={(val) => {
              setSelectedPresaleType(val);
              setIsPresaleTypeDropdownActive(false);
            }}
            dropdownItems={presaleTypeDropdownItems}
            dropdownRef={presaleTypeDropdownRef}
          />
        </div>
      </div>  */}
      {/* <OptionalFeatures />{" "} */}
      <Text primaryDefault sm className={classes.lockYourTeam}>
        Lock your team tokens with a vesting schedule for further transparency
        and trust.
      </Text>
      <div className={classes.buttonContainer}>
        <Button
          btnGray
          size="md"
          onClick={() => {
            setStep((prev) => prev - 1);
          }}
        >
          Cancel
        </Button>{" "}
        <Button
          btnPrimary
          size="md"
          onClick={() => {
            setStep((prev) => prev + 1);
          }}
        >
          Next
        </Button>
      </div>{" "}
    </div>
  );
};

export default PresaleInfo;
