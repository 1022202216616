import React, { useEffect } from "react";
import classes from "./Ieos.module.css";
import clsx from "clsx";
import LeftSide from "../../components/Ieos/LeftSide/LeftSide";
import RightSide from "../../components/Ieos/RightSide/RightSide";
import { BsDiscord, BsGithub, BsTelegram, BsTwitterX } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { useParams } from "react-router-dom";

const Ieos = () => {

  const [data, setData] = React.useState([]);

  let {ido_contract} = useParams();

//   let data = [
//     {
//       ido_name : "SH Test",
//       token_name : "SH TEST",
//       token_symbol : "SH",
//       token_description_header : "Welcome to SH",
//       description : `
//       SH Coin is an innovative digital currency that has been meticulously designed to redefine the essence of online transactions and financial privacy. It operates on a decentralized peer-to-peer network, leveraging the groundbreaking blockchain technology to ensure unparalleled security, transparency, and efficiency in every transaction. Unlike traditional fiat currencies, Avn Coin is not controlled by any central authority, making it immune to government manipulation or interference.

// The genesis of SH Coin can be traced back to a visionary group of cryptographers and software developers who identified the inherent flaws in the traditional banking system, such as high transaction fees, slow processing times, and a lack of privacy. They set out to create a digital currency that would empower individuals around the globe by providing them with full control over their financial assets without the need for intermediaries.`,
//       token_price : "1.5",
//       token_supply : "1000000",
//       tokens_for_sale : "500000",
//       tokens_for_liquidity : "500000",
//       token_sold : "500000",
//       sale_start_time : "2024-03-22",
//       sale_end_time : "2024-03-29",
//       token_end_date : "2024-03-29",
//       token_status : "Upcoming",
//       token_contract : "0x281994c3E4fc0908f720f15938d9D92eeD9565AC",
//       ido_contract : "0x281994c3E4fc0908f720f15938d9D92eeD9565AC",
//       sale_token_contract : "0x55d398326f99059fF775485246999027B3197955",
//       sale_token_symbol : "USDT",
//       liquidity_percent : "51",
//       token_image : "https://s2.coinmarketcap.com/static/img/coins/64x64/13996.png",
//       max_purchase : "1000",
//       min_purchase : "1",
//       softcap : "10",
//       hardcap : "20000",
//       sale_type : "Public",
//       ido_network : "Binance Smart Chain",
//       ido_network_chain_id : "56",

//       tokenomics : [
//         { name: "Presale", percentage: 16.6,color: "#4B73FF" },
//     { name: "Team Vesting", percentage: 16.6,color: "#F8D33A" },
//     { name: "Liquidity",percentage: 16.6, color: "#11D080" },
//     { name: "Unlocked",percentage: 16.6, color: "#FF9E2D" },
//     { name: "Locked", percentage: 16.6,color: "#FA4B55" },
//     { name: "Burnt",percentage: 16.6, color: "#2A2B2F" },
//       ],

//     socials : [
//         { icon: <BsDiscord />, to: "https://discord.com" },
//         { icon: <FaFacebook />, to: "#" },
//         { icon: <BsTwitterX />, to: "#" },
//         { icon: <BsTelegram />, to: "#" },
//         { icon: <RiInstagramFill />, to: "#" },
    
//         { icon: <BsGithub />, to: "#" },
//       ],

//     locking_on: "Unicrypt",
//     lock_amount: "1000",
//     }
//   ]

  async function fetchIDO(){
    let api = process.env.REACT_APP_API;
    let route = "/launchpads"

    let response = await fetch(api + route);
    let data = await response.json();

    let ido = data?.find((item) => item.ido_contract == ido_contract);

    console.log(ido);
    setData(ido);
  
  }
  

  useEffect(() => {
    fetchIDO();
  }, []);



  return (
    <section className={clsx("container", "paddingTop", classes.wrapper)}>
      <LeftSide data={data} />
      <RightSide data={data}/>
    </section>
  );
};

export default Ieos;
