import DatePicker from "react-datepicker";
import { CgCalendarDates } from "react-icons/cg";

// import { calendar } from "../../../images";
import classes from "./DatePicker.module.css";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";
import { useRef } from "react";

const StartDate = ({ placeholder, value, ...rest }) => {
  const datePickerRef = useRef(null); // Create a ref for the DatePicker component

  const openCalendar = () => {
    datePickerRef.current.setFocus(true); // Focus on the DatePicker input field to open the calendar
  };
  return (
    <div className={classes.datePickerRoot}>
      <DatePicker
        ref={datePickerRef}
        selected={value}
        placeholderText={placeholder}
        {...rest}
        dateFormat="MM/dd/yyyy hh:mm aa"
        showTimeSelect // Show time select
        timeFormat="HH:mm"
      />
      <CgCalendarDates
        className={classes.calendarIcon}
        onClick={openCalendar}
      />
    </div>
  );
};

export default StartDate;
